/* eslint-disable sonarjs/no-duplicate-string */
import { FC, useContext, useEffect, useState } from "react";

import { FormType, FrontendRoutes, RouteBuilders } from "@simplyk/common";
import { useQueryState, parseAsBoolean, parseAsStringLiteral, parseAsString } from "nuqs";

import { useFormV2Context } from "../context/FormV2Context";
import { HeartsAnimationProvider } from "../context/heartsAnimationContext";
import { donationFormHasTarget } from "../helpers/donationFormHasTarget";

import { DonationFormV2CheckoutFooter } from "./CheckoutFooter/DonationFormV2CheckoutFooter";
import { VersionControlAlertV2 } from "./VersionControlAlertV2";

import { NotificationBannerHeightContext } from "@/components/NotificationBanners/NotificationBannerHeightContext";
import { useLocaleContext } from "@/contexts/LocaleContext";
import { useUtmForm } from "@/features/FormV2/hooks/useUtmForm";
import { Amounts } from "@/features/FormV2/Step1/DonationForm/Amounts";
import { PeerToPeerGeneralCampaignContributeOptions } from "@/features/FormV2/Step1/DonationForm/PeerToPeer/PeerToPeerGeneralCampaignContributeOptions";
import { useDonationFirstStepSubmit } from "@/features/FormV2/Step1/DonationForm/useDonationFirststepSubmit";
import { FormV2Banner } from "@/features/FormV2/Step1/FormV2Banner";
import { FormV2LogoChip } from "@/features/FormV2/Step1/FormV2LogoChip";
import { FormV2Summary } from "@/features/FormV2/Step1/FormV2Summary";
import { Donor } from "@/features/FormV2/Step1/PeerToPeer/Donor";
import { DonorsInformationDialog } from "@/features/FormV2/Step1/PeerToPeer/DonorsInformationDialog";
import { DonorsTile } from "@/features/FormV2/Step1/PeerToPeer/DonorsTile";
import { FundraiseDialog } from "@/features/FormV2/Step1/PeerToPeer/FundraiseDialog/FundraiseDialog";
import { FundraisersInformationDialog } from "@/features/FormV2/Step1/PeerToPeer/FundraisersInformationDialog";
import { FundraisersTile } from "@/features/FormV2/Step1/PeerToPeer/FundraisersTile";
import { IndividualFundraiser } from "@/features/FormV2/Step1/PeerToPeer/IndividualFundraiser";
import { TeamFundraiser } from "@/features/FormV2/Step1/PeerToPeer/TeamFundraiser";
import { PeerToPeerFormV2DonateToPrimaryCampaignStep1Layout } from "@/features/FormV2/Step1/PeerToPeerFormV2DonateToPrimaryCampaignStep1Layout";
import { PeerToPeerFormV2PrimaryCampaignStep1Layout } from "@/features/FormV2/Step1/PeerToPeerFormV2PrimaryCampaignStep1Layout";
import { usePreviewContext } from "@/features/LiveFormEditor/LivePreview/context/PreviewContext";
import { trpc } from "@/helpers/trpc";

export type PeerToPeerFormV2PrimaryCampaignStep1Props = {
  isEmbed?: boolean;
};

export const PeerToPeerFormV2PrimaryCampaignStep1: FC<PeerToPeerFormV2PrimaryCampaignStep1Props> = ({
  isEmbed = false,
}) => {
  const { isoLocale } = useLocaleContext();
  const { formObject, hasBanner, formCategory, formCurrency } = useFormV2Context(FormType.DonationForm);
  const { isExpanded, bannerHeight } = useContext(NotificationBannerHeightContext);
  const { isPreview } = usePreviewContext();
  const { canSubmitFirstStep } = useDonationFirstStepSubmit();
  const utm = useUtmForm();

  const { data: getFundraisersSummaryResponse } = trpc.getCampaignFundraisersSummary.useQuery({
    campaignId: formObject.campaignId!,
  });

  const [donateQuery] = useQueryState("donate", parseAsBoolean.withDefault(false).withOptions({ history: "push" }));
  const [fundraisersQuery, setFundraisersQuery] = useQueryState(
    "fundraisers",
    parseAsStringLiteral(["view-all", "donate-to-campaign"] as const).withOptions({ history: "push" })
  );
  const [donorsQuery, setDonorsQuery] = useQueryState(
    "donors",
    parseAsStringLiteral(["view-all"] as const).withOptions({ history: "push" })
  );
  const [fundraiseQuery, setFundraiseQuery] = useQueryState(
    "openFundraiser",
    parseAsBoolean.withDefault(false).withOptions({ history: "push" })
  );
  const [searchQuery, setSearchQuery] = useQueryState("search", parseAsString.withDefault(""));
  const [fundraisersSearchQuery, setFundraisersSearchQuery] = useQueryState(
    "fundraisersSearch",
    parseAsString.withDefault("")
  );

  const fundraisers = getFundraisersSummaryResponse ?? { items: [], hasMore: false };
  const shouldDisplayFundraisers = fundraisers.items.length > 0;

  const isDonateMode =
    donateQuery || (!formObject.allowTeamCreation && !formObject.allowFundraiserCreation && !shouldDisplayFundraisers);
  const isDonorsModalOpen = donorsQuery === "view-all";
  const isFundraisersModalOpen = fundraisersQuery === "view-all" || fundraisersQuery === "donate-to-campaign";
  const isFundraiseDialogOpen = fundraiseQuery;

  // We debounce the search text to avoid spamming the server with requests
  const [searchText, setSearchText] = useState(searchQuery);
  const [fundraisersSearchText, setFundraisersSearchText] = useState(fundraisersSearchQuery);

  const isDonationFormWithTarget = donationFormHasTarget(formCategory);

  // Fetch recent donors if relevant
  const { data: getLatestDonationsResponse } = trpc.getLatestDonations.useQuery(
    {
      formId: formObject.id,
      limit: 6,
      cursor: 0,
    },
    {
      enabled: isDonationFormWithTarget,
      initialData: { items: [] },
    }
  );

  const latestDonations =
    getLatestDonationsResponse?.items?.map((donation) => ({
      ...donation,
      donatedOn: new Date(donation.donatedOn),
      donationAmount: donation.amount,
      donationCurrency: donation.currency,
    })) ?? [];

  const shouldDisplayLatestDonations = isDonationFormWithTarget && latestDonations.length > 0;

  const { isFetching: isSearchingFundraisers, data: searchFundraisersSummaryResponse } =
    trpc.getCampaignFundraisersSummary.useQuery(
      {
        campaignId: formObject.campaignId!,
        search: searchQuery,
      },
      { enabled: !!searchQuery && !isFundraisersModalOpen }
    );

  const searchedFundraisers = searchFundraisersSummaryResponse ?? { items: [], hasMore: false };

  useEffect(() => {
    // Debounce the search to avoid spamming the server with requests
    let timeout: NodeJS.Timeout;

    if (searchQuery !== searchText) {
      timeout = setTimeout(() => {
        setSearchQuery(searchText);
      }, 400);
    }

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    // Debounce the search to avoid spamming the server with requests
    let timeout: NodeJS.Timeout;

    if (fundraisersSearchQuery !== fundraisersSearchText) {
      timeout = setTimeout(() => {
        setFundraisersSearchQuery(fundraisersSearchText);
      }, 400);
    }

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fundraisersSearchText]);

  const redirectUrl = formObject.ticketingId
    ? `${process.env.NEXT_PUBLIC_FRONTEND_URL}/${isoLocale}${FrontendRoutes.TicketingPage}${formObject.ticketingId}`
    : formObject.redirectUrl;

  const renderFundraisersTile = () => (
    <FundraisersTile
      isLoading={searchQuery !== searchText || isSearchingFundraisers}
      searchText={searchText}
      onSearchTextChange={setSearchText}
      onViewAllClick={() => setFundraisersQuery("view-all")}
      showViewAllButton={fundraisers.hasMore}
    >
      {(searchQuery ? searchedFundraisers.items : fundraisers.items).map(({ type, path, ...fundraiser }, index) => {
        if (type === "team") {
          return (
            <TeamFundraiser
              key={index}
              {...fundraiser}
              currency={formCurrency}
              href={RouteBuilders.buildPeerToPeerV2TeamCampaignLink({ isoLocale, path }).path}
            />
          );
        }

        return (
          <IndividualFundraiser
            key={index}
            {...fundraiser}
            currency={formCurrency}
            href={RouteBuilders.buildPeerToPeerV2IndividualCampaignLink({ isoLocale, path }).path}
            isPartOfTeam={fundraiser.isPartOfTeam}
          />
        );
      })}
    </FundraisersTile>
  );

  const renderDonorsTile = () => (
    <DonorsTile
      showViewAllButton={!!getLatestDonationsResponse?.nextCursor}
      onViewAllClick={() => setDonorsQuery("view-all")}
    >
      {latestDonations.map((donation, index) => (
        <Donor {...donation} key={index} />
      ))}
    </DonorsTile>
  );

  return (
    <HeartsAnimationProvider>
      {isDonateMode ? (
        <PeerToPeerFormV2DonateToPrimaryCampaignStep1Layout
          isEmbed={isEmbed}
          contextDrawerHeight={isExpanded ? bannerHeight : 0}
          slots={{
            banner: hasBanner ? <FormV2Banner /> : undefined,
            organizationLogo: <FormV2LogoChip />,
            summary: <FormV2Summary forceInlined={isEmbed} />,
            donors: shouldDisplayLatestDonations ? renderDonorsTile() : undefined,
            donationAmounts: <Amounts />,
            versionControlAlert: <VersionControlAlertV2 />,
            checkoutFooter: <DonationFormV2CheckoutFooter />,
          }}
          showCheckoutFooter={canSubmitFirstStep && !isPreview}
          utm={utm}
        />
      ) : (
        <PeerToPeerFormV2PrimaryCampaignStep1Layout
          isEmbed={isEmbed}
          contextDrawerHeight={isExpanded ? bannerHeight : 0}
          slots={{
            banner: hasBanner ? <FormV2Banner /> : undefined,
            organizationLogo: <FormV2LogoChip />,
            summary: <FormV2Summary forceInlined={isEmbed} />,
            donors: shouldDisplayLatestDonations ? renderDonorsTile() : undefined,
            fundraisers: shouldDisplayFundraisers ? renderFundraisersTile() : undefined,
            donateOrFundraiseOptions: (
              <PeerToPeerGeneralCampaignContributeOptions
                allowTeamCreation={Boolean(formObject.allowTeamCreation)}
                allowFundraiserCreation={Boolean(formObject.allowFundraiserCreation)}
                hasRegistration={!!formObject.ticketingId}
                registrationIsMandatory={formObject.hideFundraiseButton}
                onDonateButtonClick={() => setFundraisersQuery("donate-to-campaign")}
                onFundraiseButtonClick={() => setFundraiseQuery(true)}
                redirectUrl={redirectUrl}
              />
            ),
          }}
          utm={utm}
        />
      )}
      {shouldDisplayLatestDonations && (
        <DonorsInformationDialog
          donationFormId={formObject.id}
          open={isDonorsModalOpen}
          onClose={() => setDonorsQuery(null)}
        />
      )}
      <FundraisersInformationDialog
        mode={fundraisersQuery === "view-all" ? "find-fundraiser-campaign" : "donate-to-campaign"}
        campaignId={formObject.campaignId!}
        open={isFundraisersModalOpen}
        search={fundraisersSearchQuery}
        onSearchChange={setFundraisersSearchText}
        formCurrency={formCurrency}
        onClose={() => {
          setFundraisersQuery(null);
          setFundraisersSearchQuery(null);
        }}
      />
      <FundraiseDialog
        open={isFundraiseDialogOpen}
        onClose={() => setFundraiseQuery(false)}
        campaignId={formObject.campaignId as string}
        allowTeamCreation={Boolean(formObject.allowTeamCreation)}
        allowFundraiserCreation={Boolean(formObject.allowFundraiserCreation)}
      />
    </HeartsAnimationProvider>
  );
};
